import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPropertiesView as _setPropertiesView,
  resetPropertiesView as _resetPropertiesView,
  setPropertiesViewVisible as _setPropertiesViewVisible,
  PropertiesUiSliceState,
  selectPropertiesView,
  selectPropertiesViewVisible,
} from '../propertiesSlice';

interface UsePropertiesUiReturnType {
  propertiesView: PropertiesUiSliceState['ui']['view'];

  setPropertiesView: (args: Partial<PropertiesUiSliceState['ui']['view']>) => void;
  resetPropertiesView: () => void;

  propertiesViewVisible: boolean;
  setPropertiesViewVisible: (visible: boolean) => void;
}

export default function usePropertiesUi(): UsePropertiesUiReturnType {
  const propertiesView = useSelector(selectPropertiesView);
  const propertiesViewVisible = useSelector(selectPropertiesViewVisible);

  const dispatch = useDispatch();

  const setPropertiesView = useCallback(
    (args: Partial<PropertiesUiSliceState['ui']['view']>) => {
      dispatch(_setPropertiesView(args));
    },
    [dispatch],
  );

  const resetPropertiesView = useCallback(() => {
    dispatch(_resetPropertiesView());
  }, [dispatch]);

  const setPropertiesViewVisible = useCallback(
    (visible: boolean) => {
      dispatch(_setPropertiesViewVisible(visible));
    },
    [dispatch],
  );

  return {
    propertiesView,
    setPropertiesView,
    resetPropertiesView,
    propertiesViewVisible,
    setPropertiesViewVisible,
  };
}
