import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import { BaseProperties, EventTypes } from './types';

export interface CaptureArgs<T extends keyof EventTypes> {
  name: string;
  properties: BaseProperties & EventTypes[T];
}

export interface IdentifyArgs {
  id: string;
  set?: Record<string, any>;
  setOnce?: Record<string, any>;
}

export interface UseAnalyticsReturnType {
  capture: <T extends keyof EventTypes>(args: CaptureArgs<T>) => void;
  identify: (args: IdentifyArgs) => void;
  getUserId: () => string;
}

export default function useAnalytics() {
  const posthog = usePostHog();

  const identify = useCallback(
    (args: IdentifyArgs) => {
      // Make sure we have not already identified the user
      if (posthog.get_distinct_id() === args.id) return;
      posthog.identify(args.id, args.set, args.setOnce);
    },
    [posthog],
  );

  const capture = useCallback(
    <T extends keyof EventTypes>(name: string, properties: BaseProperties & EventTypes[T]) => {
      if (!posthog) {
        throw new Error('Posthog is not initialized');
      }

      const eventMetaProperties = {
        environment: process.env.REACT_APP_ENVIRONMENT || 'dev',
        timestamp: new Date().toISOString(),
        source: 'hubspot app',
      };

      posthog.capture(name, { ...properties, ...eventMetaProperties });
    },
    [posthog],
  );

  const getUserId = useCallback(() => {
    return posthog.get_distinct_id();
  }, [posthog]);

  return { capture, identify, getUserId };
}
