/**
 * This is the currently used tab menu and it is based on the PrimeReact TabView component.
 * The reason we are using the tabview component even if it does not really fit out needs is
 * because it has a scrollable feature that we need to use.
 */

import { useEffect, useMemo, useState } from 'react';
import { useObjectTypes } from '../../../objectTypes';
import { useGetProperties } from '../../hooks';
import usePropertiesUi from '../../hooks/usePropertiesUi';
import { snakeCaseToTitleCase, splitPrimaryAndAssociatedProperties } from '../../utils';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import styles from './properties-controls.module.css';
import { useAnalytics, EventTypeName } from '../../../../services/analytics';

export default function PropertiesTabMenuAlt() {
  const [activeIndex, setActiveIndex] = useState(0);

  const { properties } = useGetProperties();
  const { setPropertiesView } = usePropertiesUi();
  const { selectedObjectType } = useObjectTypes();

  const { capture } = useAnalytics();

  // Split properties into primary and associated properties
  const { associated: associationProperties } = useMemo(() => {
    return splitPrimaryAndAssociatedProperties(properties);
  }, [properties]);

  const handleTabChange = (e: TabViewTabChangeEvent) => {
    if (e.index === 0) setPropertiesView({ activePropertyGroup: 'primary' });
    else setPropertiesView({ activePropertyGroup: associationProperties[e.index - 1].name });
    setActiveIndex(e.index);
  };

  // reset the active index when the selected object type changes
  useEffect(() => {
    setActiveIndex(0);
  }, [selectedObjectType, setActiveIndex]);

  return (
    <TabView
      scrollable
      activeIndex={activeIndex}
      onTabChange={(e: TabViewTabChangeEvent) => {
        if (e.index !== 0) {
          capture<EventTypeName.ASSOCIATED_PROPERTIES_VIEWED>(EventTypeName.ASSOCIATED_PROPERTIES_VIEWED, {
            objectTypeId: selectedObjectType?.objectTypeId || '',
            objectTypeName: selectedObjectType?.objectTypeName || '',
            isCustomObjectType: selectedObjectType?.isCustom || false,
            associatedPropertiesGroupName: associationProperties[e.index - 1].name,
          });
        }
        handleTabChange(e);
      }}
      className={`max-w-full`}
      pt={{ panelContainer: { className: '!h-0 !p-0' } }}
    >
      <TabPanel header={selectedObjectType?.pluralLabel} className={`${styles.primary} ${styles.tab}`} />
      {associationProperties.length ? (
        associationProperties.map(({ name }, index) => (
          <TabPanel
            key={index}
            header={snakeCaseToTitleCase(name)}
            className={index === 0 ? `${styles.association} ${styles.tab}` : ''}
          />
        ))
      ) : (
        <TabPanel header='No associations' disabled className={styles.disabled} />
      )}
    </TabView>
  );
}
