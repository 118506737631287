import { useMemo } from 'react';
import { useGetAllObjectTypesQuery, useObjectTypes } from '../../../objectTypes';
import { useGetObjectsPage, useObjectsUi } from '../../hooks';
import useObjects from '../../hooks/useObjects';
import SelectionControlsSkeleton from '../../../../components/SelectionControlsSkeleton';
import { Message } from 'primereact/message';
import { findObjectTitle } from '../../utils';

export default function ObjectSelectionControls() {
  const { selectedObjectType } = useObjectTypes();
  const { selectedObject } = useObjects();
  const { setObjectModalVisibility } = useObjectsUi();

  const getAllObjectTypesResponse = useGetAllObjectTypesQuery();
  const { objectsPageMeta } = useGetObjectsPage();

  const isLoading: boolean = useMemo(() => {
    const {
      isLoading: objectTypesLoading,
      isFetching: objectTypesFetching,
      isUninitialized: objectTypesNotInitialized,
    } = getAllObjectTypesResponse;

    const {
      isLoading: objectsLoading,
      isFetching: objectsFetching,
      isUninitialized: objectsNotInitialized,
    } = objectsPageMeta;

    return (
      objectTypesLoading ||
      objectTypesFetching ||
      objectTypesNotInitialized ||
      objectsLoading ||
      objectsFetching ||
      objectsNotInitialized
    );
  }, [getAllObjectTypesResponse, objectsPageMeta]);

  const objectTitle: string | undefined = useMemo(
    () => (selectedObject ? findObjectTitle(selectedObject) : undefined),
    [selectedObject],
  );

  return (
    <>
      {objectsPageMeta?.isError || getAllObjectTypesResponse?.isError ? (
        <Message
          severity='error'
          text={`Error loading ${getAllObjectTypesResponse.isError ? 'object types' : 'objects'}`}
        />
      ) : isLoading ? (
        <SelectionControlsSkeleton />
      ) : (
        <div className='flex flex-col gap-2'>
          {/* object type label */}
          <p className='text-slate-500 max-w-[180px] overflow-hidden overflow-ellipsis whitespace-nowrap text-sm'>
            {selectedObjectType?.singularLabel}
          </p>

          {/* object controls */}
          <div className='flex gap-5 items-center'>
            <p
              className={`text-md max-w-[180px] overflow-hidden whitespace-nowrap overflow-ellipsis ${
                (!selectedObject || !objectTitle) && 'text-slate-400'
              }`}
            >
              {!selectedObject ? 'None selected' : !objectTitle ? 'Untitled' : objectTitle}
            </p>

            <p
              className='text-primary-500 text-sm hover:underline cursor-pointer'
              onClick={() => setObjectModalVisibility(true)}
            >
              {selectedObject ? 'change' : 'select'}
            </p>
          </div>
          {!selectedObject && <Message severity='warn' className='text-xs mt-2' text='Select an object to continue' />}
        </div>
      )}
    </>
  );
}
