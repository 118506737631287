import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { PostHogProvider } from 'posthog-js/react';
import { posthog } from 'posthog-js';

import App from './App';

import './index.css';
import './primereact-theme/themes/soho/soho-light/theme.scss';
import 'primeicons/primeicons.css';
import { Provider } from 'react-redux';
import { store } from './store/index';

const posthogApiKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '';
if (!posthogApiKey) {
  throw new Error('Missing POSTHOG_API_KEY environment variable');
}

posthog.init(posthogApiKey, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  autocapture: false,
  capture_pageview: true,
  rageclick: true,
  // Only track identified events when we identify the user. We will identify each user at login time.
  person_profiles: 'identified_only',
});

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <App />
      </Provider>
    </PostHogProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
