/**
 * Object types - AKA Schemas - are the data structures that define the structure for objects of a given object type.
 */

import { apiSlice } from '../../api';
import { ListResponse } from '../../api/types';
import { transformListResponse, transformSingleResponse } from '../../api/utils';

// Options are based of the Hubspot's schema data. Currently only string, bool, datetime, number, are supported.
export type PropertyTypeOptions = 'string' | 'bool' | 'datetime' | 'number';

// Properties require a name, label, and type to be displayed on the client.
export interface DisplayPropertiesType {
  name: string;
  label: string;
  default: boolean;
  type: PropertyTypeOptions;
}

export interface CustomObjectType {
  objectTypeId: string;
  objectTypeName: string;
  primaryDisplayProperty: string;
  // Is used defined or not.
  isCustom: boolean;
  properties: DisplayPropertiesType[];
  singularLabel: string;
  pluralLabel: string;
}

const OBJECT_TYPE_URL = 'object-types';

export const objectTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllObjectTypes: builder.query<CustomObjectType[], void>({
      query: () => `/${OBJECT_TYPE_URL}`,
      providesTags: (response, error: any) => {
        if (error) throw new Error(error.message);
        if (response) {
          return [
            { id: 'LIST', type: 'ObjectTypes' as const },
            ...response.map(({ objectTypeId: id }) => ({ id, type: 'Objects' as const })),
          ];
        }
        return [{ id: 'LIST', type: 'ObjectTypes' as const }];
      },
      transformResponse: (response: ListResponse<CustomObjectType>) => transformListResponse(response).data,
    }),

    // Not used currently
    // Return all custom (HubSpot user defined) object schemas.
    getCustomObjectTypes: builder.query<CustomObjectType[], void>({
      query: () => `/${OBJECT_TYPE_URL}/custom-objects`,
      providesTags: (response, error: any) => {
        if (error) throw new Error(error.message);
        if (response) {
          return [
            { id: 'LIST', type: 'CustomObjectTypes' as const },
            ...response.map(({ objectTypeId: id }) => ({ id, type: 'CustomObjectTypes' as const })),
          ];
        }
        return [{ id: 'LIST', type: 'CustomObjectTypes' as const }];
      },
      transformResponse: (response: ListResponse<CustomObjectType>) => transformListResponse(response).data,
    }),

    getObjectType: builder.query<CustomObjectType, string>({
      query: (objectTypeId) => `/${OBJECT_TYPE_URL}/${objectTypeId}`,
      providesTags: ['ObjectType'],
      transformResponse: transformSingleResponse<CustomObjectType>,
    }),
  }),
});

export const { useGetAllObjectTypesQuery, useGetCustomObjectTypesQuery, useGetObjectTypeQuery } = objectTypesApi;
