export enum EventTypeName {
  TEMPLATE_SETTINGS_CLICKED = 'template settings clicked', // ✅
  $PAGE_VIEW = '$pageview',
  $PAGE_LEAVE = '$pageleave',
  TEST = 'test',
  ACTIVE_TEMPLATE_CHANGED = 'active template changed', // ✅
  ACTIVE_OBJECT_CHANGED = 'active object changed', // ✅
  ACTIVE_OBJECT_TYPE_CHANGED = 'active object type changed', // ✅
  PROPERTIES_VIEWED = 'properties viewed', // ✅
  ASSOCIATED_PROPERTIES_VIEWED = 'associated properties viewed', // ✅
  DOCUMENT_DOWNLOADED = 'document downloaded', // ✅
}

export interface ObjectTypeProperties {
  objectTypeId: string;
  objectTypeName: string;
  isCustomObjectType: boolean;
}

export interface EventTypes {
  [EventTypeName.TEMPLATE_SETTINGS_CLICKED]: {};
  [EventTypeName.TEST]: { calledFrom: string };
  [EventTypeName.$PAGE_VIEW]: {};
  [EventTypeName.$PAGE_LEAVE]: {};
  [EventTypeName.ACTIVE_TEMPLATE_CHANGED]: {
    templateId: string;
  };
  [EventTypeName.ACTIVE_OBJECT_CHANGED]: ObjectTypeProperties & {
    objectId: string;
  };
  [EventTypeName.ACTIVE_OBJECT_TYPE_CHANGED]: { objectType: string };
  [EventTypeName.PROPERTIES_VIEWED]: ObjectTypeProperties & {};
  [EventTypeName.ASSOCIATED_PROPERTIES_VIEWED]: ObjectTypeProperties & {
    associatedPropertiesGroupName: string;
  };
  [EventTypeName.DOCUMENT_DOWNLOADED]: {}; // used
}

export interface BaseProperties {
  // User Context
  userEmail?: string;

  // Prevents a profile from being created for the user. Use this if you ever use UUIDs as user IDs.
  $process_person_profile?: boolean;
  // Set person properties.
  $set?: Record<string, any>;
  // Set person properties only the first time they are set.
  $set_once?: Record<string, any>;

  // Groups
  groups?: Record<string, string>;
}
