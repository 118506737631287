import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { useObjectTypes } from '../hooks';
import { mapObjectTypeToDropdownOption } from '../utils';
import { CustomObjectType } from '../objectTypesApi';
import { SelectItem } from 'primereact/selectitem';
import { HiOutlineUser } from 'react-icons/hi';
import { objectTypesApi } from '../objectTypesApi';
import _ from 'lodash';
import { useAnalytics, EventTypeName } from '../../../services/analytics';

interface ExtraProps {
  handleChange?: (args: { originalEvent: any; objectTypeId: string }) => void;
  includeCustom?: boolean;
}

type ObjectTypeDropdownProps = Omit<DropdownProps, 'options' | 'value'> & ExtraProps;

type optionGroupProperties = {
  label: string;
  code: string;
  items: SelectItem;
};

const STANDARD_OBJECT_TYPES = ['CONTACT', 'COMPANY', 'DEAL', 'TICKET'];

function isStandardObjectType(objectTypeName: string) {
  return STANDARD_OBJECT_TYPES.includes(objectTypeName);
}

export default function ObjectTypeDropdown(props: ObjectTypeDropdownProps) {
  const { className, filter = false, includeCustom = true, handleChange: customHandleChange, ...rest } = props;
  const { objectTypeId, selectedObjectType, objectTypeList, setObjectTypeId } = useObjectTypes();
  const [value, setValue] = useState<string | null>(null);

  const objectsState = objectTypesApi.endpoints.getAllObjectTypes.useQueryState();

  const { capture } = useAnalytics();

  useEffect(() => {
    if (!selectedObjectType) {
      setValue(objectTypeId);
    } else {
      setValue(selectedObjectType.objectTypeId);
    }
  }, [objectTypeId, setValue, selectedObjectType]);

  // Options for dropdown menu.
  const options = useMemo(() => {
    if (!objectTypeList) {
      return [
        {
          label: 'HubSpot',
          code: 'hubspot',
          items: [{ value: objectTypeId, label: _.startCase(objectTypeId?.toLowerCase() || '') }],
        },
      ];
    }

    const hubspotObjects = objectTypeList?.filter(({ objectTypeName }: CustomObjectType) =>
      isStandardObjectType(objectTypeName),
    );
    const customObjects = objectTypeList?.filter(
      ({ objectTypeName }: CustomObjectType) => !isStandardObjectType(objectTypeName),
    );

    const groupedDropdownOptions = [
      {
        label: 'Standard Objects',
        code: 'hubspot',
        items: hubspotObjects?.map(mapObjectTypeToDropdownOption),
      },
    ];

    // If includeCustom is true, add custom objects to the dropdown.
    if (includeCustom) {
      groupedDropdownOptions.push({
        label: 'Custom Objects',
        code: 'custom',
        items: customObjects?.map(mapObjectTypeToDropdownOption),
      });
    }

    return groupedDropdownOptions;
  }, [objectTypeList, objectTypeId, includeCustom]);

  const handleChange = useCallback(
    (event: DropdownChangeEvent) => {
      const objectTypeId = event.target.value;

      capture<EventTypeName.ACTIVE_OBJECT_TYPE_CHANGED>(EventTypeName.ACTIVE_OBJECT_TYPE_CHANGED, {
        objectType: objectTypeId,
      });

      if (customHandleChange) {
        customHandleChange({ originalEvent: null, objectTypeId });
        return;
      }

      setObjectTypeId(objectTypeId);
    },
    [setObjectTypeId, customHandleChange, capture],
  );

  const groupedItemTemplate = (option: optionGroupProperties) => {
    return (
      <div className='-ml-2 flex items-center gap-2 w-full h-full bg-transparent'>
        {option.code === 'hubspot' ? (
          <img
            src='./images/hubspot-logo.svg'
            alt='hs logo'
            style={{ width: 16, color: 'red' }}
            title='HubSpot Defined'
          />
        ) : (
          <HiOutlineUser className='w-[25px] h-[25px] text-slate-600' />
        )}
        <p className='font-bold text-md text-slate-600 whitespace-nowrap'>{option.label}</p>
      </div>
    );
  };

  return (
    <Dropdown
      options={options}
      optionValue='value'
      optionLabel='label'
      optionGroupLabel='label'
      optionGroupChildren='items'
      optionGroupTemplate={groupedItemTemplate}
      value={value}
      filter={filter}
      loading={!objectTypeId && objectsState.isLoading}
      dropdownIcon={(a) => <i className='pi pi-sort-down-fill text-slate-500'></i>}
      //@ts-ignore
      // We have to remove this for time because we downgraded primereact to 10.8.2 to allow property copying.
      // collapseIcon={() => <i className='pi pi-sort-up-fill'></i>}
      onChange={(e) => handleChange(e)}
      className={`p-inputtext-sm ${className}`}
      {...rest}
    />
  );
}
