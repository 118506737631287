import { useDebounce } from 'primereact/hooks';
import ControlledSearchButtonInput from '../../../../components/ControlledSearchButtonInput';
import { ObjectTypeDropdown, useObjectTypes } from '../../../objectTypes';
import { useGetObjectsPage, useObjectsUi } from '../../hooks';
import { useCallback, useEffect } from 'react';
import { Button } from 'primereact/button';

export default function ObjectListHeader() {
  const { selectedObjectType } = useObjectTypes();
  const { setObjectParams, setObjectModalVisibility } = useObjectsUi();
  const { objectsPageMeta } = useGetObjectsPage();

  const isFetching = objectsPageMeta.isFetching;

  const [searchValue, debouncedSearchValue, setDebouncedSearchValue] = useDebounce<string | undefined>(undefined, 500);

  useEffect(() => {
    setObjectParams({ searchValue: debouncedSearchValue, after: 0 });
  }, [debouncedSearchValue, setObjectParams]);

  const reload = useCallback(() => {
    objectsPageMeta.refetch();
  }, [objectsPageMeta]);

  return (
    <div className='grid grid-cols-3 py-2 px-1'>
      {/* LEFT */}
      <div className='flex justify-start items-center gap-3 '>
        <ObjectTypeDropdown filter={true} />
        <ControlledSearchButtonInput value={searchValue || ''} handleValueChange={(e) => setDebouncedSearchValue(e)} />
      </div>

      {/* CENTER */}
      <div className='flex justify-center items-center'>
        <h4 className='text-2xl font-medium text-slate-600'>{`Select a ${
          selectedObjectType?.singularLabel || 'Object'
        }`}</h4>
      </div>

      {/* RIGHT */}
      <div className='flex justify-end items-center gap-3 '>
        <Button
          size='small'
          onClick={reload}
          disabled={isFetching}
          icon='pi pi-refresh'
          outlined
          severity='secondary'
        />

        <Button
          size='small'
          onClick={() => setObjectModalVisibility(false)}
          icon='pi pi-times'
          outlined
          severity='secondary'
        />
      </div>
    </div>
  );
}
