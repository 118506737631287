import { useDispatch, useSelector } from 'react-redux';
import { ExpandedObjectInterface, useLazyGetObjectQuery } from '../objectsApi';
import { selectObjectId, setObjectId as _setObjectId, selectAllObjects } from '../objectsSlice';
import { useCallback, useMemo } from 'react';
import { useObjectTypes } from '../../objectTypes';

export interface UseObjectsReturnType {
  selectedObject: ExpandedObjectInterface | undefined;
  allObjects: ExpandedObjectInterface[];
  objectId: string | null;

  setObjectId(objectId: string): void;
}

export default function useObjects(): UseObjectsReturnType {
  const [lazyGetObject] = useLazyGetObjectQuery();

  const objectId = useSelector(selectObjectId);
  const allObjects = useSelector((state: any) => selectAllObjects(state));
  const { objectTypeId } = useObjectTypes();

  const dispatch = useDispatch();

  // Because we fetch objects in batches we may need to make individual requests if the selected object is not in the list
  const getObject = useCallback(
    (objectId: string) => {
      if (!objectTypeId || !objectId) {
        return;
      }

      lazyGetObject({ objectId, objectType: objectTypeId, queryParams: {} });
    },
    [objectTypeId, lazyGetObject],
  );

  // Get selected object and make a request if it is not in the list
  const selectedObject = useMemo(() => {
    if (!objectId) return undefined;

    const object = allObjects?.find((o) => o.id === objectId);
    if (!object) {
      getObject(objectId);
    } else return object;
  }, [allObjects, objectId, getObject]);

  const setObjectId = useCallback(
    (objectId: string) => {
      dispatch(_setObjectId(objectId));
    },
    [dispatch],
  );

  return {
    selectedObject,
    allObjects,
    objectId,
    setObjectId,
  };
}
