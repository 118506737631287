import { SelectItem } from 'primereact/selectitem';
import { useObjectTypes } from '../../../objectTypes';
import { useGetProperties } from '../../hooks';
import usePropertiesUi from '../../hooks/usePropertiesUi';
import { useMemo } from 'react';
import { snakeCaseToTitleCase, splitPrimaryAndAssociatedProperties } from '../../utils';
import { Dropdown } from 'primereact/dropdown';
import { useAnalytics, EventTypeName } from '../../../../services/analytics';

type PropertyOption = {
  label: string;
  code: string;
  items: SelectItem[];
};

export default function PropertiesDropdown() {
  const { properties, propertiesMeta } = useGetProperties();
  const {
    setPropertiesView,
    propertiesView: { activePropertyGroup },
  } = usePropertiesUi();
  const { selectedObjectType } = useObjectTypes();

  // Split properties into primary and associated properties
  const { associated: associationProperties, primary: primaryProperties } = useMemo(() => {
    return splitPrimaryAndAssociatedProperties(properties);
  }, [properties]);

  const { capture } = useAnalytics();

  const options: PropertyOption[] | undefined = useMemo(() => {
    if (!selectedObjectType || !primaryProperties) return undefined;

    return [
      {
        label: 'Primary',
        code: 'primary',
        items: [
          {
            label: `${selectedObjectType.pluralLabel}`,
            value: 'primary',
          },
        ],
      },
      {
        label: 'Associations',
        code: 'associations',
        items: associationProperties.length
          ? associationProperties.map(({ name }) => ({
              label: snakeCaseToTitleCase(name),
              value: name,
            }))
          : [],
      },
    ];
  }, [selectedObjectType, primaryProperties, associationProperties]);

  const groupedItemTemplate = ({
    optionGroup,
    code,
    label,
  }: {
    code: string;
    group: boolean;
    index: number;
    label: string;
    optionGroup: PropertyOption;
  }) => {
    return (
      <div className='flex flex-col w-full gap-2'>
        <div className='-ml-2 flex items-center gap-2 w-full h-full bg-transparent overflow-hidden'>
          {/* {code === 'primary' ? (
            <img
              src='./images/hubspot-logo.svg'
              alt='hs logo'
              style={{ width: 16, color: 'red' }}
              title='HubSpot Defined'
            />
          ) : (
            <TbTopologyStar className='w-[25px] h-[25px] text-slate-600' />
          )} */}

          {/* label */}
          <p className='font-bold text-md text-slate-600 whitespace-nowrap text-ellipsis'>{label}</p>
        </div>

        {/* Display a message if this object type does not support associations */}
        {code === 'associations' && !optionGroup.items.length && (
          <div className='w-full p-2'>
            <p className='text-sm text-slate-400 max-w-full'>Associations are not yet supported for this object type</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <Dropdown
      options={options}
      value={activePropertyGroup}
      optionValue='value'
      optionLabel='label'
      optionGroupLabel='label'
      optionGroupChildren='items'
      optionGroupTemplate={groupedItemTemplate}
      loading={propertiesMeta.isFetching || propertiesMeta.isUninitialized}
      onChange={(event) => {
        if (event.value !== 'primary') {
          capture<EventTypeName.ASSOCIATED_PROPERTIES_VIEWED>(EventTypeName.ASSOCIATED_PROPERTIES_VIEWED, {
            objectTypeId: selectedObjectType?.objectTypeId || '',
            objectTypeName: selectedObjectType?.objectTypeName || '',
            isCustomObjectType: selectedObjectType?.isCustom || false,
            associatedPropertiesGroupName: event.value,
          });
        }
        setPropertiesView({ activePropertyGroup: event.value });
      }}
      className='max-w-[15rem] overflow-hidden'
      pt={{
        input: { className: 'max-w-full text-ellipses text-nowrap whitespace-nowrap' },
        panel: { className: 'max-w-[18rem]' },
      }}
    />
  );
}
