import { CustomProperty } from '../features/properties/propertiesApi';

/**
 * Converts HubSpot properties directly to JSON Schema
 * No grouping, just a simple property-to-schema conversion
 * @param properties Array of HubSpot properties
 * @returns JSON Schema object
 */
export function hubspotToJsonSchema(properties: CustomProperty[]): any {
  // Create base schema
  const schema: any = {
    $schema: 'http://json-schema.org/draft-07/schema',
    type: 'object',
    properties: {},
  };

  // Process all top-level properties (skip nested collection properties)
  properties.forEach((prop) => {
    // Skip properties that are part of a collection's relatedObjectProperties
    if (prop.key.includes('-')) return;

    // Convert the property to a JSON schema property
    schema.properties[prop.key] = convertPropertyToSchema(prop);
  });

  return schema;
}

/**
 * Converts a single HubSpot property to a JSON Schema property
 * @param property HubSpot property
 * @returns JSON Schema property object
 */
function convertPropertyToSchema(property: CustomProperty): any {
  // Handle collections specially
  if (property.type === 'collection' && property.relatedObjectProperties) {
    const collectionSchema: {
      type: string;
      items: {
        type: string;
        properties: Record<string, any>;
      };
    } = {
      type: 'array',
      items: {
        type: 'object',
        properties: {},
      },
    };

    // Add all related properties to the collection schema
    property.relatedObjectProperties.forEach((relatedProp) => {
      const propName = relatedProp.name;
      collectionSchema.items.properties[propName] = getPropertyTypeSchema(relatedProp);
    });

    return collectionSchema;
  }

  // For non-collection types, return basic schema
  return getPropertyTypeSchema(property);
}

/**
 * Returns schema definition based on property type
 * @param property The HubSpot property
 * @returns JSON Schema type definition
 */
function getPropertyTypeSchema(property: CustomProperty): any {
  const schema: any = {};

  // Map property type to JSON schema type
  switch (property.type) {
    case 'number':
      schema.type = 'number';
      break;
    case 'datetime':
    case 'date':
      schema.type = 'date';
      break;
    case 'boolean':
      schema.type = 'boolean';
      break;
    case 'enumeration':
      schema.type = 'string';
      break;
    default:
      schema.type = 'string';
  }

  return schema;
}
