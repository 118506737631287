import { apiSlice } from '../../api';
import { getCurrentParams } from '../../api/utils';

export interface GetApiKeyResponse {
  data: {
    apiKeyPrefix: string;
  };
}

export type ValidateApiKeyParams = string;

export interface ValidateApiKeyResponse {
  message: string;
  isValid: boolean;
}

// Based off the response for server. Server returns authInfo containing:
export interface LoginResponseType {
  sub?: string; // Just another portalId. Do not know why.
  objectId?: string;
  objectType?: string;
  userEmail?: string;
  userId?: string;
  portalId?: string;
  apiKeyPrefix?: string;
  isAdmin?: boolean;
}

export type User = Pick<LoginResponseType, 'objectId' | 'objectType' | 'isAdmin' | 'userId' | 'userEmail'>;

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<User, { sessionToken: string }>({
      query: ({ sessionToken }) => ({
        url: '/auth/login',
        method: 'POST',
        headers: { Authorization: `Bearer ${sessionToken}` },
      }),
      transformResponse: (response: { data: LoginResponseType }) => {
        if (!response.data) throw new Error('Response type mismatch at login');
        const { objectId, objectType, isAdmin, userId, userEmail } = response.data;
        return {
          objectId,
          objectType,
          isAdmin,
          userId,
          userEmail,
        };
      },
    }),

    // API KEYS
    getApiKey: builder.query<GetApiKeyResponse, void>({
      query: () => '/auth/api-keys',
      providesTags: ['ApiKey'],
    }),

    addApiKey: builder.mutation<string, string>({
      query: (apiKey) => ({
        url: '/auth/api-keys',
        body: { apiKey },
        method: 'PUT',
        params: getCurrentParams(),
      }),
      invalidatesTags: ['ApiKey', 'ApiKeyValidity'],
    }),

    checkApiKeyValidity: builder.query<{ isValid: boolean }, void>({
      query: () => '/auth/api-keys/validity',
      providesTags: ['ApiKeyValidity'],
    }),

    validateApiKey: builder.mutation<ValidateApiKeyResponse, ValidateApiKeyParams | void>({
      query: (apiKey?) => ({
        url: '/auth/api-keys/validate',
        body: apiKey ? { apiKey } : undefined,
        method: 'POST',
      }),
      invalidatesTags: ['ApiKey', 'ApiKeyValidity'],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetApiKeyQuery,
  useLazyGetApiKeyQuery,
  useAddApiKeyMutation,
  useCheckApiKeyValidityQuery,
  useLazyCheckApiKeyValidityQuery,
  useValidateApiKeyMutation,
} = authApi;
