import { apiSlice } from '../../api';

import { SingleResponse } from '../../api/types';

export type Document = {
  id: string;
  url: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  account: string;
};

export interface CreateDocumentParams {
  objectId: string;
  objectTypeId: string;
  templateId: string;
  isLive?: boolean;
  source?: 'quick-create' | 'app';
}

export const documentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDocument: builder.mutation<Document, CreateDocumentParams>({
      query: ({ objectId, templateId, isLive = false, objectTypeId, source }) => {
        const queryParams: Record<string, any> = { source: source || 'app' };
        if (isLive) {
          queryParams.active = true;
        } else {
          queryParams.preview = true;
        }

        return {
          url: `/templates/${templateId}/${objectTypeId}/${objectId}`,
          method: 'POST',
          params: queryParams,
        };
      },
      transformResponse: (response: SingleResponse<Document>) => response.data,
    }),
  }),
});

export const { useCreateDocumentMutation } = documentApi;

export type UseCreateDocumentMutation = typeof useCreateDocumentMutation;
