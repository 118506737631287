import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiKey, useAuth } from '../../features/auth';
import { useLazyCheckTokenValidityQuery } from '../../features/auth/hubspotApi';

import './style.css';
import { retrieveSessionTokenFromSessionStorage, storeSessionTokenInSession } from '../../api';
import LottieLoader from '../../components/LottieLoader';

const UNAUTHORIZED_REDIRECT_PATH = '/unauthorized';

const Login = () => {
  const isLoggingInRef = useRef<boolean>(false);

  const { getUser } = useAuth();
  const { checkValidity } = useApiKey();
  const [checkTokenValidity] = useLazyCheckTokenValidityQuery();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { redirectTo } = state || {};

  // Navigation functions
  const redirectLogin = useCallback(() => {
    navigate(redirectTo || '/dashboard');
  }, [navigate, redirectTo]);

  const redirectApiKey = useCallback(() => {
    navigate('/api-key', { state: { redirectTo: redirectTo || '/dashboard' } });
  }, [navigate, redirectTo]);

  const redirectAuthorize = useCallback(() => {
    return navigate('/authorize');
  }, [navigate]);

  const redirectNoAuth = useCallback(() => {
    navigate(UNAUTHORIZED_REDIRECT_PATH);
  }, [navigate]);

  // Template method to perform login actions
  const doLogin = useCallback(
    async (token: string) => {
      try {
        Promise.all([getUser(token), checkTokenValidity(), checkValidity()])
          .then(([userResponse, tokenValidity, apiKeyValidity]) => {
            // Get User
            if (!userResponse) return redirectNoAuth();
            // Validate Scopes
            if (tokenValidity?.isError || tokenValidity?.data?.error) return redirectAuthorize();
            // Validate the API Key
            if (apiKeyValidity?.isError || !apiKeyValidity?.data?.isValid) return redirectApiKey();

            redirectLogin();
          })
          .catch((error) => {
            console.error(error);
            return redirectNoAuth();
          });

        // Target loading progress value
      } catch (error) {
        console.error(error);
      }
    },
    [getUser, checkValidity, checkTokenValidity, redirectLogin, redirectNoAuth, redirectApiKey, redirectAuthorize],
  );

  // Initiates the login process, by getting the token from the URL or local storage
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    // get the token from the url or session storage
    const urlToken = search.get('session_token');
    const storedToken = retrieveSessionTokenFromSessionStorage();
    const token = urlToken ?? storedToken;

    // if the token is not a string, redirect to unauthorized
    if (typeof token !== 'string') return redirectNoAuth();

    // If the new token does not match the stored token, update the stored token
    if (token !== storedToken) storeSessionTokenInSession(token);

    if (!isLoggingInRef.current) {
      isLoggingInRef.current = true;
      doLogin(token);
    }
  }, [doLogin, redirectNoAuth]);

  return (
    <div
      className='w-full h-screen flex justify-center items-center bg-primary-50'
      style={{ borderRadius: '20%', boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 1)' }}
    >
      <div style={{ width: '230px' }}>
        <LottieLoader />
      </div>
    </div>
  );
};

export default Login;
